import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { SeoLink } from 'dibs-elements/exports/SeoLink';
import { SEGMENTS } from 'dibs-constants/exports/uriConstants';
import { isSellerBrandingRemovalTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';
import { useSbSelector } from '../../reducers/useSbSelector';

import {
    checkRewardedSeller,
    checkTradeUserUnderTopLevelLoyaltyTiers,
} from 'dibs-buyer-layout/exports/sellerBrandingHelpers';

import { type SbSharedStorefrontLink_browseContentModule$data } from './__generated__/SbSharedStorefrontLink_browseContentModule.graphql';
import { type SbSharedStorefrontLink_user$data } from './__generated__/SbSharedStorefrontLink_user.graphql';

import styles from './SbSharedStorefrontLink.scss';

type Props = {
    user: SbSharedStorefrontLink_user$data;
    browseContentModule: SbSharedStorefrontLink_browseContentModule$data;
};

export const SbSharedStorefrontLinkBase: FC<Props> = ({ browseContentModule, user }) => {
    const seller = browseContentModule.seller;
    const company = seller?.sellerProfile?.company;

    const isClient = useSbSelector(state => state.relayVariables.variables.isClient);

    if (!seller || !company) {
        return null;
    }

    const shouldShowSellerDescription =
        checkRewardedSeller(seller) ||
        (isClient &&
            !isSellerBrandingRemovalTestVariant() &&
            checkTradeUserUnderTopLevelLoyaltyTiers(user || null));

    if (!shouldShowSellerDescription) {
        return null;
    }

    const urlLabel = seller.sellerPreferences?.urlLabel;
    const isLinkable = !!urlLabel && seller.liveStorefrontProfile?.status === 'LIVE';
    const path = isLinkable ? `/${SEGMENTS.SELLER}/${urlLabel}/` : null;

    return (
        <div className={styles.wrapper}>
            (
            <FormattedMessage
                id="SbSharedStorefrontLink.bioProvidedBy"
                defaultMessage="Biography provided by"
            />{' '}
            <SeoLink
                linkData={{
                    path,
                    isLinkable,
                }}
                dataTn="bio-seller-link"
            >
                {company}
            </SeoLink>
            )
        </div>
    );
};
