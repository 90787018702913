/**
 * @generated SignedSource<<d331cb1d0c617a33fb09db5d4078785b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespContentModuleRegular_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedStorefrontLink_user">;
  readonly " $fragmentType": "SbRespContentModuleRegular_user";
};
export type SbRespContentModuleRegular_user$key = {
  readonly " $data"?: SbRespContentModuleRegular_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespContentModuleRegular_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespContentModuleRegular_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedStorefrontLink_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f3d39a0e1c845368b0a389127dba495c";

export default node;
