import get from 'lodash/get';
import { ELIGIBLE_CATEGORIES_L2, NO_BANNER_REGIONS } from './sbRespTradeOnApprovalPromoConstants';
import {
    getCategories,
    getCategory,
    getCategoryUrlLabel,
    CATEGORY_LEVEL_2,
} from '../../../utils/categoryHelpers';

export function getUSMetroLocationFacets({ locationFacets, usRegions }) {
    const usMetroRegions = usRegions.filter(
        region => get(region, 'regionBoundary.type.name') === 'METROPOLITAN'
    );
    const usCityUrlLabels = new Set(usMetroRegions.map(region => region.urlLabel));
    return locationFacets.filter(facet => usCityUrlLabels.has(facet.label));
}

const getMatchingUSRegion = (filters, usRegions) => {
    const locationFilter = filters.find(f => f.name === 'location');
    const locationFilterValues = get(locationFilter, 'values') || [];
    const locationFilterUrlLabels = locationFilterValues.map(value => value.urlLabel);
    const usRegionUrlLabels = new Set(usRegions.map(region => region.urlLabel));
    const matchingUSRegion = locationFilterUrlLabels.find(urlLabel =>
        usRegionUrlLabels.has(urlLabel)
    );

    return matchingUSRegion;
};

export function showTradeOnApprovalBanner({ appliedFilters, usRegions }) {
    const categories = getCategories(appliedFilters);
    const categoryL2 = getCategory(categories, CATEGORY_LEVEL_2);
    const categoryL2UrlLabel = getCategoryUrlLabel(categoryL2);
    const matchingUSRegion = getMatchingUSRegion(appliedFilters, usRegions);

    const isEligibleL2 = ELIGIBLE_CATEGORIES_L2.includes(categoryL2UrlLabel);
    const isUSLoc = !!matchingUSRegion;
    const isNoBannerRegion = NO_BANNER_REGIONS.includes(matchingUSRegion);

    return isEligibleL2 && isUSLoc && !isNoBannerRegion;
}
