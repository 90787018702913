import { type FC } from 'react';
import { type SbSharedStorefrontLink_browseContentModule$data } from './__generated__/SbSharedStorefrontLink_browseContentModule.graphql';
import { type SbSharedStorefrontLink_user$data } from './__generated__/SbSharedStorefrontLink_user.graphql';

import { SbSharedStorefrontLinkBase } from './SbSharedStorefrontLinkBase';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

type Props = {
    browseContentModule: SbSharedStorefrontLink_browseContentModule$data;
    user: SbSharedStorefrontLink_user$data;
};

export const SbSharedStorefrontLinkComponent: FC<Props> = ({ browseContentModule, user }) => {
    return <SbSharedStorefrontLinkBase browseContentModule={browseContentModule} user={user} />;
};

export const SbSharedStorefrontLink = createFragmentContainer(SbSharedStorefrontLinkComponent, {
    user: graphql`
        fragment SbSharedStorefrontLink_user on User {
            ...sellerBrandingHelpers_user
        }
    `,
    browseContentModule: graphql`
        fragment SbSharedStorefrontLink_browseContentModule on ContentModuleRegular {
            seller {
                ...sellerBrandingHelpers_seller
                sellerProfile {
                    company
                }
                sellerPreferences {
                    urlLabel
                }
                liveStorefrontProfile {
                    status
                }
            }
        }
    `,
});
