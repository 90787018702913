import { type FC } from 'react';
import { type SbRespContentModuleRegular_browseContentModule$data } from './__generated__/SbRespContentModuleRegular_browseContentModule.graphql';
import { type SbRespContentModuleRegular_user$data } from './__generated__/SbRespContentModuleRegular_user.graphql';

import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SbRespContentModuleBase } from './../SbRespContentModuleBase/SbRespContentModuleBase';
import { SbSharedStorefrontLink } from '../SbSharedStorefrontLink/SbSharedStorefrontLink';

type Props = {
    browseContentModule: SbRespContentModuleRegular_browseContentModule$data;
    user: SbRespContentModuleRegular_user$data;
    hidePhoto: boolean;
    className?: string;
};

const SbRespContentModuleRegularComponent: FC<Props> = ({
    browseContentModule,
    hidePhoto,
    className,
    user,
}) => {
    const { description, title, photo } = browseContentModule;

    return (
        <SbRespContentModuleBase
            title={title}
            description={description}
            photo={hidePhoto ? null : photo}
            className={className}
        >
            <SbSharedStorefrontLink browseContentModule={browseContentModule} user={user} />
        </SbRespContentModuleBase>
    );
};

export const SbRespContentModuleRegular = createFragmentContainer(
    SbRespContentModuleRegularComponent,
    {
        user: graphql`
            fragment SbRespContentModuleRegular_user on User {
                ...SbSharedStorefrontLink_user
            }
        `,
        browseContentModule: graphql`
            fragment SbRespContentModuleRegular_browseContentModule on ContentModuleRegular {
                ...SbSharedStorefrontLink_browseContentModule
                description
                title
                photo
            }
        `,
    }
);
