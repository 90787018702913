/**
 * @generated SignedSource<<cb52c233d5769b5304c10b60ee295ddd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type StorefrontProfilesStatus = "DELETED" | "LIVE" | "PENDING_TRANSLATION" | "REJECTED" | "UNDER_REVIEW" | "WORK_IN_PROGRESS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SbSharedStorefrontLink_browseContentModule$data = {
  readonly seller: {
    readonly liveStorefrontProfile: {
      readonly status: StorefrontProfilesStatus | null;
    } | null;
    readonly sellerPreferences: {
      readonly urlLabel: string | null;
    } | null;
    readonly sellerProfile: {
      readonly company: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"sellerBrandingHelpers_seller">;
  } | null;
  readonly " $fragmentType": "SbSharedStorefrontLink_browseContentModule";
};
export type SbSharedStorefrontLink_browseContentModule$key = {
  readonly " $data"?: SbSharedStorefrontLink_browseContentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedStorefrontLink_browseContentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedStorefrontLink_browseContentModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "sellerBrandingHelpers_seller",
          "selections": [
            {
              "alias": "isRewarded",
              "args": [
                {
                  "kind": "Literal",
                  "name": "listNames",
                  "value": [
                    "SELLER_REWARDS_1",
                    "SELLER_REWARDS_2"
                  ]
                }
              ],
              "kind": "ScalarField",
              "name": "isAnchorListsMember",
              "storageKey": "isAnchorListsMember(listNames:[\"SELLER_REWARDS_1\",\"SELLER_REWARDS_2\"])"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SellerProfile",
          "kind": "LinkedField",
          "name": "sellerProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SellerPreferences",
          "kind": "LinkedField",
          "name": "sellerPreferences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlLabel",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StorefrontProfile",
          "kind": "LinkedField",
          "name": "liveStorefrontProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentModuleRegular",
  "abstractKey": null
};

(node as any).hash = "0c4c69c6a1e40e265c7f23f24ed71f34";

export default node;
