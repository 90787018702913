import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';

import {
    REGULAR_MODULE_TYPE,
    BANNER_MODULE_TYPE,
    BANNER_TILES_MODULE_TYPE,
} from '../../constants/contentModuleConstants';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { useSbSelector } from '../../reducers/useSbSelector';

import { SbRespContentModuleRegular } from '../SbRespContentModuleRegular/SbRespContentModuleRegular';
import { SbSharedContentModuleBanner } from '../SbSharedContentModuleBanner/SbSharedContentModuleBanner';
import { SbSharedContentModuleBannerTiles } from '../SbSharedContentModuleBannerTiles/SbSharedContentModuleBannerTiles';

import {
    checkRewardedSeller,
    checkTradeUserUnderTopLevelLoyaltyTiers,
} from 'dibs-buyer-layout/exports/sellerBrandingHelpers';
import { isSellerBrandingRemovalTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';

import { type SbRespContentModuleSelector_itemSearch$key } from './__generated__/SbRespContentModuleSelector_itemSearch.graphql';
import { type SbRespContentModuleSelector_user$key } from './__generated__/SbRespContentModuleSelector_user.graphql';

const itemFragment = graphql`
    fragment SbRespContentModuleSelector_itemSearch on ItemSearchQueryConnection {
        pageType
        seller {
            ...sellerBrandingHelpers_seller
        }
        browseContentModule(contentModuleId: $contentModuleId) {
            moduleType
            ...SbRespContentModuleRegular_browseContentModule
            ...SbSharedContentModuleBanner_browseContentModule
            ...SbSharedContentModuleIconicDesign_browseContentModule
            ...SbSharedContentModuleBannerTiles_browseContentModule
        }
    }
`;

const userFragment = graphql`
    fragment SbRespContentModuleSelector_user on User {
        ...SbRespContentModuleRegular_user
        ...sellerBrandingHelpers_user
    }
`;

type Props = {
    itemSearch: SbRespContentModuleSelector_itemSearch$key;
    user: SbRespContentModuleSelector_user$key;
    className?: string;
    hidePhoto: boolean;
};

export const SbRespContentModuleSelector: FC<Props> = ({
    itemSearch: itemSearchRef,
    user: userRef,
    className,
    hidePhoto,
}) => {
    const isClient = useSbSelector(state => state.relayVariables.variables.isClient);
    const itemSearch = useFragment(itemFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);
    const { browseContentModule, pageType, seller } = itemSearch || {};
    const isSalePage = pageType === pageTypeConstants.SALE;
    const isDealerShopPage = pageType === pageTypeConstants.DEALER;

    if (isDealerShopPage) {
        if (!isClient) {
            return null;
        }

        const shouldShowSellerDescription =
            checkRewardedSeller(seller) ||
            (!isSellerBrandingRemovalTestVariant() &&
                checkTradeUserUnderTopLevelLoyaltyTiers(user || null));
        if (!shouldShowSellerDescription) {
            return null;
        }
    }

    if (!browseContentModule) {
        return null;
    }

    switch (browseContentModule.moduleType) {
        case REGULAR_MODULE_TYPE:
            return (
                <SbRespContentModuleRegular
                    browseContentModule={browseContentModule}
                    hidePhoto={hidePhoto}
                    className={className}
                    user={user}
                />
            );
        case BANNER_MODULE_TYPE:
            return (
                <SbSharedContentModuleBanner
                    browseContentModule={browseContentModule}
                    renderImage={({ src, imgClassName, alt }) => (
                        <img src={src} className={imgClassName || ''} alt={alt || ''} />
                    )}
                />
            );
        case BANNER_TILES_MODULE_TYPE:
            return (
                <SbSharedContentModuleBannerTiles
                    browseContentModule={browseContentModule}
                    isSalePage={isSalePage}
                />
            );
        default:
            return null;
    }
};
